import "/assets/styles/pages/basket.scss";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// Context
import { useDeliveryInfo } from "/controllers/delivery";
import { useNavigator } from "pstv-commerce-tools/utilities/navigator";
import { useBasket } from "/controllers/basket";
import { useModals } from "pstv-commerce-tools/utilities/modals";
import { useGlobalEvents } from "pstv-commerce-tools/utilities/global-events";
import { useAuth } from "pstv-commerce-tools/utilities/auth";

// Partials
import { Input, FormFeedback } from "/views/partials/forms";
import Loader from "/views/partials/loader";
import CheckoutSummary from "/views/partials/checkout-summary";
import Btn from "/views/partials/btn";
import Pricebox from "/views/partials/pricebox";
import Productbox from "/views/partials/productbox";
import Icon from "pstv-commerce-tools/partials/icon";

// Services
import saleServices from "/services/sale";

const Basket = () => {
    const { redirect } = useNavigator();

    const { openModal } = useModals();

    const { triggerEvent } = useGlobalEvents();
    const {
        busy,
        summary,
        deliveryLimits,
        products,
        clearBasket: clearBasketOnContext,
        initialized,
        getBasketData: getBasketOnContext,
    } = useBasket();

    const { selectedDelivery, saveAddress } = useDeliveryInfo();

    const { userData } = useAuth();

    const [submitting, setSubmitting] = useState(false);

    const [feedbackData, setFeedbackData] = useState(false);

    const [isCorporate, setIsCorporate] = useState({
        value: userData?.customer_corporate ? "2" : "1",
        label: userData?.customer_corporate
            ? "Kurumsal Müşteri"
            : "Bireysel Müşteri",
    });

    useEffect(() => {
        if (summary?.basket_quantity > 0) {
            if (userData?.customer_corporate) {
                setIsCorporate({
                    value: "2",
                    label: "Kurumsal Müşteri",
                }); // ! Kurumsal müşteri checkbox seçili
                saleServices.updateInvoiceType(userData.id, "2").finally(() => {
                    getBasketOnContext();
                });
            } else {
                setIsCorporate({
                    value: "1",
                    label: "Bireysel Müşteri",
                }); // ! Bireysel müşteri checkbox seçili
                saleServices
                    .updateInvoiceType(userData.id, "1")

                    .finally(() => {
                        getBasketOnContext();
                    });
            }
        }
    }, [userData]);

    const {
        minOrderAmount,
        minOrderDiffAmount,
        canProceed,
        freeDeliveryDiffAmount,
        freeDeliveryAmount,
    } = useMemo(() => {
        const isCorporateSelected = isCorporate.value === "2";

        // Kurumsal veya bireysel teslimat limitleri
        const minBasketLimit = isCorporateSelected
            ? deliveryLimits?.corporate_home_delivery_min_basket
            : deliveryLimits?.home_delivery_min_basket;

        const freeDeliveryLimit = isCorporateSelected
            ? deliveryLimits?.corporate_home_delivery_free_limit
            : deliveryLimits?.home_delivery_free_limit;

        if (minBasketLimit && summary?.grand_total) {
            const minOrderDiffAmount =
                minBasketLimit.original - summary.grand_total.original;

            const freeDeliveryDiffAmount =
                freeDeliveryLimit.original - summary.grand_total.original;

            return {
                minOrderAmount: minBasketLimit,
                freeDeliveryAmount: freeDeliveryLimit,
                minOrderDiffAmount: {
                    ...summary.grand_total,
                    original: minOrderDiffAmount,
                    original_str: minOrderDiffAmount
                        .toFixed(2)
                        .replace(".", ","), // Sayısal değerler string'e dönüştürülüyor
                },
                freeDeliveryDiffAmount: {
                    ...summary.grand_total,
                    original: freeDeliveryDiffAmount,
                    original_str: freeDeliveryDiffAmount
                        .toFixed(2)
                        .replace(".", ","), // Sayısal değerler string'e dönüştürülüyor
                },
                canProceed: minOrderDiffAmount <= 0,
            };
        } else {
            return {
                minOrderAmount: false,
                minOrderDiffAmount: false,
                freeDeliveryAmount: false,
                freeDeliveryDiffAmount: false,
                canProceed: false,
            };
        }
    }, [initialized, deliveryLimits, summary, isCorporate.value]);

    useEffect(() => {
        localStorage.removeItem("active_sale_id");
    }, []);

    const prepareAndProceed = (addressId) => {
        saleServices
            .prepare(Number(isCorporate.value))
            .then((sale) => {
                saleServices
                    .setAddresses(sale.id, addressId, 0)
                    .then(() => {
                        localStorage.setItem("active_sale_id", sale.id);

                        triggerEvent("salePrepare", { sale });
                        triggerEvent("saleSetAddress", {
                            sale,
                            addressId,
                        });
                        redirect("checkout");
                    })
                    .catch((feedback) => {
                        setFeedbackData(feedback);
                        setSubmitting(false);
                    });
            })
            .catch((feedback) => {
                setFeedbackData(feedback);
                setSubmitting(false);
            });
    };

    const submitBasket = () => {
        setFeedbackData(false);
        if (selectedDelivery?.address) {
            setSubmitting(true);
            if (selectedDelivery.address.id) {
                prepareAndProceed(selectedDelivery.address.id);
            } else {
                saveAddress(selectedDelivery.address)
                    .then((savedAddress) => {
                        prepareAndProceed(savedAddress.id);
                    })
                    .catch((feedback) => {
                        setSubmitting(false);
                        setFeedbackData(feedback);
                    });
            }
        } else {
            openModal("address", {
                message:
                    "Siparişinizi tamamlamak için bir teslimat adresi seçmelisiniz.",
            });
        }
    };

    const clearBasket = () => {
        openModal("confirm", {
            message:
                "Sepetinizdeki tüm ürünleri silmek istediğinizden emin misiniz?",
            confirmText: "Tümünü Sil",
            onConfirm: () => {
                clearBasketOnContext();
            },
        });
    };

    const updateInvoiceType = (e) => {
        setIsCorporate(e);
        // saleServices
        saleServices.updateInvoiceType(userData.id, e.value).finally(() => {
            getBasketOnContext();
        });
    };

    return (
        <div className="checkout-wrap wrapper">
            <section className="checkout-wrap-main has-header">
                <header className="main-header">
                    <h1 className="header-title">Sepetim</h1>

                    {products.length > 0 && (
                        <Btn
                            disabled={busy}
                            onClick={clearBasket}
                            className="nude"
                            icon="trash"
                        >
                            Sepeti Temizle
                        </Btn>
                    )}
                </header>

                <FormFeedback className="main-feedback" data={feedbackData} />
                <div className="loader-container">
                    <Loader solid loading={!initialized} />
                    {products.length > 0 ? (
                        <ul className="basket-products">
                            {products.map((product) => (
                                <li
                                    className="products-item"
                                    key={product.basket_id}
                                >
                                    <Productbox
                                        product={product}
                                        type="basket"
                                    />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="basket-emptymessage">
                            <Icon className="emptymessage-icon" name="cart" />
                            <strong className="emptymessage-text">
                                Sepetiniz boş
                            </strong>
                            <Btn className="primary" tag="link" href="home">
                                Alışverişe Başla
                            </Btn>
                        </div>
                    )}
                </div>
            </section>
            <aside className="checkout-wrap-aside loader-container">
                <Loader solid loading={!initialized} />
                <div className="aside-content">
                    <MinTotalMessage
                        minOrderAmount={minOrderAmount}
                        minOrderDiffAmount={minOrderDiffAmount}
                        freeDeliveryDiffAmount={freeDeliveryDiffAmount}
                        freeDeliveryAmount={freeDeliveryAmount}
                        canProceed={canProceed}
                    />
                    {products.length > 0 && (
                        <>
                            <div className="aside-total">
                                <strong className="total-title">
                                    Toplam Tutar <span>(KDV Dahil)</span>
                                </strong>

                                <Pricebox
                                    className="total-price"
                                    big
                                    price={summary.grand_total}
                                />
                            </div>

                            {userData?.customer_corporate && (
                                <div className="corporate-message-checkbox">
                                    <div className="corporate-message-content">
                                        <label className="corporate-message-label">
                                            Fatura Tipi
                                        </label>

                                        <div className="corporate-message-text">
                                            <Input
                                                disabled={
                                                    !canProceed || submitting
                                                }
                                                type="radio"
                                                className="is-corporate-inputs"
                                                value={isCorporate}
                                                onChange={(e) => {
                                                    // setIsCorporate(e);
                                                    // localStorage.setItem(
                                                    //     "isCorporate",
                                                    //     e.value
                                                    // );
                                                    updateInvoiceType(e);
                                                }}
                                                options={[
                                                    {
                                                        label: "Kurumsal Fatura",
                                                        value: "2",
                                                    },
                                                    {
                                                        label: "Bireysel Fatura",
                                                        value: "1",
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    <Btn
                        disabled={!products.length || !canProceed || submitting}
                        loading={submitting}
                        onClick={submitBasket}
                        className="aside-cta block primary"
                    >
                        Alışverişi Tamamla
                    </Btn>
                </div>
                <CheckoutSummary
                    summary={summary}
                    deliveryLimits={deliveryLimits}
                />
            </aside>
        </div>
    );
};

const MinTotalMessage = ({
                             minOrderAmount,
                             minOrderDiffAmount,
                             canProceed,
                             freeDeliveryDiffAmount,
                             freeDeliveryAmount,
                         }) => {
    if (!canProceed && minOrderDiffAmount && minOrderDiffAmount?.original > 0) {
        return (
            <div className="basket-mintotalmessage">
                <Icon className="mintotalmessage-icon" name="info" />
                <div className="mintotalmessage-text">
                    <p>
                        <strong>
                            Sipariş verebilmek için{" "}
                            <Pricebox
                                className="text-diffamount"
                                price={minOrderDiffAmount}
                            />{" "}
                            tutarında
                        </strong>{" "}
                        ürün eklemelisiniz.
                    </p>
                    <p>
                        Minimum sepet tutarı:{" "}
                        <Pricebox price={minOrderAmount} />
                    </p>
                </div>
            </div>
        );
    } else if (canProceed && freeDeliveryDiffAmount?.original > 0) {
        return (
            <div className="basket-mintotalmessage">
                <Icon className="mintotalmessage-icon" name="info" />
                <div className="mintotalmessage-text">
                    <p>
                        <strong>
                            Ücretsiz teslimat için sepetinize{" "}
                            <Pricebox
                                className="text-diffamount"
                                price={freeDeliveryDiffAmount}
                            />{" "}
                            tutarında
                        </strong>{" "}
                        ürün eklemelisiniz.
                    </p>
                    <p>
                        <Pricebox price={freeDeliveryAmount} /> ve üzeri
                        ücretsiz teslimat
                    </p>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

MinTotalMessage.propTypes = {
    minOrderAmount: PropTypes.object,
    minOrderDiffAmount: PropTypes.object,
    freeDeliveryAmount: PropTypes.object,
    freeDeliveryDiffAmount: PropTypes.object,
    canProceed: PropTypes.bool,
};

export default Basket;
