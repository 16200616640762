import '/assets/styles/pages/basket.scss';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Context
import { useDeliveryInfo } from '/controllers/delivery';
import { useNavigator } from 'pstv-commerce-tools/utilities/navigator'
import { useBasket } from '/controllers/basket';
import { useModals } from 'pstv-commerce-tools/utilities/modals';
import { useGlobalEvents } from 'pstv-commerce-tools/utilities/global-events';

// Partials
import { FormFeedback } from '/views/partials/forms';
import Loader from '/views/partials/loader';
import CheckoutSummary from '/views/partials/checkout-summary';
import Btn from '/views/partials/btn';
import Pricebox from '/views/partials/pricebox';
import Productbox from '/views/partials/productbox';
import Icon from 'pstv-commerce-tools/partials/icon';

// Services
import saleServices from '/services/sale'

const Basket = () => {
	const { redirect } = useNavigator();
	const { openModal } = useModals();
	const { triggerEvent } = useGlobalEvents();
	const { busy, summary, deliveryLimits, products, clearBasket: clearBasketOnContext, initialized } = useBasket();
	const { selectedDelivery, saveAddress } = useDeliveryInfo();

	const [submitting, setSubmitting] = useState(false);
	const [feedbackData, setFeedbackData] = useState(false);

	const { minOrderAmount, minOrderDiffAmount, canProceed, freeDeliveryDiffAmount, freeDeliveryAmount } = useMemo(() => {
		if(deliveryLimits?.home_delivery_min_basket && summary?.grand_total) {
			const minOrderDiffAmount = deliveryLimits.home_delivery_min_basket.original - summary.grand_total.original;

			const freeDeliveryDiffAmount = deliveryLimits.home_delivery_free_limit.original - summary.grand_total.original;

			return {
				minOrderAmount: deliveryLimits.home_delivery_min_basket,
				freeDeliveryAmount: deliveryLimits.home_delivery_free_limit,
				minOrderDiffAmount: {
					...summary.grand_total,
					original: minOrderDiffAmount,
					original_str: minOrderDiffAmount.toFixed(2).replace('.', ','),
				},
				freeDeliveryDiffAmount: {
					...summary.grand_total,
					original: freeDeliveryDiffAmount,
					original_str: freeDeliveryDiffAmount.toFixed(2).replace('.', ','),
				},
				canProceed: minOrderDiffAmount <= 0,
			}
		}
		else {
			return {
				minOrderAmount: false,
				minOrderDiffAmount: false,
				freeDeliveryAmount: false,
				freeDeliveryDiffAmount: false,
				canProceed: false,
			};
		}
	}, [initialized, deliveryLimits, summary])

	useEffect(() => {
		localStorage.removeItem('active_sale_id');
	}, [])

	const prepareAndProceed = (addressId) => {
		saleServices.prepare().then(sale => {
			saleServices.setAddresses(sale.id, addressId, 0).then(() => {
				localStorage.setItem('active_sale_id', sale.id);
				triggerEvent('salePrepare', { sale });
				triggerEvent('saleSetAddress', { sale, addressId });
				redirect('checkout');
			}).catch(feedback => {
				setFeedbackData(feedback);
				setSubmitting(false);
			})
		}).catch(feedback => {
			setFeedbackData(feedback);
			setSubmitting(false);
		})
	}

	const submitBasket = () => {
		setFeedbackData(false);
		if(selectedDelivery?.address) {
			setSubmitting(true);
			if(selectedDelivery.address.id) {
				prepareAndProceed(selectedDelivery.address.id);
			}
			else {
				saveAddress(selectedDelivery.address).then(savedAddress => {
					prepareAndProceed(savedAddress.id);
				}).catch(feedback => {
					setSubmitting(false);
					setFeedbackData(feedback);
				})
			}
		}
		else {
			openModal('address', { message: 'Siparişinizi tamamlamak için bir teslimat adresi seçmelisiniz.' });
		}
	}

	const clearBasket = () => {
		openModal('confirm', {
			message: 'Sepetinizdeki tüm ürünleri silmek istediğinizden emin misiniz?',
			confirmText: 'Tümünü Sil',
			onConfirm: () => {
				clearBasketOnContext();
			}
		});
	}

	return (
		<div className="checkout-wrap wrapper">
			<section className="checkout-wrap-main has-header">
				<header className="main-header">
					<h1 className="header-title">Sepetim</h1>

					{products.length > 0 &&
						<Btn
							disabled={busy}
							onClick={clearBasket}
							className="nude"
							icon="trash">
							Sepeti Temizle
						</Btn>
					}
				</header>
				<FormFeedback className="main-feedback" data={feedbackData} />
				<div className="loader-container">
					<Loader solid loading={!initialized} />
					{products.length > 0 ?
						<ul className="basket-products">
							{products.map((product) => (
								<li className="products-item" key={product.basket_id}>
									<Productbox product={product} type="basket" />
								</li>
							))}
						</ul>
						:
						<div className="basket-emptymessage">
							<Icon className="emptymessage-icon" name="cart" />
							<strong className="emptymessage-text">Sepetiniz boş</strong>
							<Btn className="primary" tag="link" href="home">
								Alışverişe Başla
							</Btn>
						</div>
					}
				</div>
			</section>
			<aside className="checkout-wrap-aside loader-container">
				<Loader solid loading={!initialized} />
				<div className="aside-content">
					<MinTotalMessage
						minOrderAmount={minOrderAmount}
						minOrderDiffAmount={minOrderDiffAmount}
						freeDeliveryDiffAmount={freeDeliveryDiffAmount}
						freeDeliveryAmount={freeDeliveryAmount}
						canProceed={canProceed} />
					{products.length > 0 &&
						<div className="aside-total">
							<strong className="total-title">
								Toplam Tutar <span>(KDV Dahil)</span>
							</strong>
							
							<Pricebox className="total-price" big price={summary.grand_total} />
						</div>
					}

					<Btn
						disabled={!products.length || !canProceed || submitting}
						loading={submitting}
						onClick={submitBasket}
						className="aside-cta block primary">
						Alışverişi Tamamla
					</Btn>
				</div>
				<CheckoutSummary summary={summary} />
			</aside>
		</div>
	)
}

const MinTotalMessage = ({ minOrderAmount, minOrderDiffAmount, canProceed, freeDeliveryDiffAmount, freeDeliveryAmount }) => {
	if(!canProceed && minOrderDiffAmount && minOrderDiffAmount?.original > 0) {
		return (
			<div className="basket-mintotalmessage">
				<Icon className="mintotalmessage-icon" name="info" />
				<div className="mintotalmessage-text">
					<p>
						<strong>Sipariş verebilmek için <Pricebox className="text-diffamount" price={minOrderDiffAmount} /> tutarında</strong> ürün eklemelisiniz.
					</p>
					<p>Minimum sepet tutarı: <Pricebox price={minOrderAmount} /></p>
				</div>
			</div>
		)
	}
	else if(canProceed && freeDeliveryDiffAmount?.original > 0) {
		return <div className="basket-mintotalmessage">
		<Icon className="mintotalmessage-icon" name="info" />
		<div className="mintotalmessage-text">
			<p>
				<strong>Ücretsiz teslimat için sepetinize <Pricebox className="text-diffamount" price={freeDeliveryDiffAmount} /> tutarında</strong> ürün eklemelisiniz.
			</p>
			<p>Ücretsiz teslimat limiti: <Pricebox price={freeDeliveryAmount} /></p>
		</div>
	</div>
	}
	else {
		return null;
	}
}

MinTotalMessage.propTypes = {
	minOrderAmount: PropTypes.object,
	minOrderDiffAmount: PropTypes.object,
	freeDeliveryAmount: PropTypes.object,
	freeDeliveryDiffAmount: PropTypes.object,
	canProceed: PropTypes.bool,
}

export default Basket
